import style from './Explorer.module.css'
import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames/bind'
import { Swipeable } from '../helpers/swipeable'
import { useState, useEffect, useRef, useContext } from 'react'
import { LayoutContext } from './sg/Layout'
import { useRouter } from 'next/router'
import useAuth from '../hooks/useAuth.js'
import Link from 'next/link'
import useSWR from 'swr'
import resolveProductUnit from '../helpers/resolveProductUnit'

export default function Explorer () {
  const c = classNames.bind(style)
  const { t } = useTranslation('main')
  const backingTab = useRef()
  const ownersTab = useRef()
  const router = useRouter()
  const { locale } = router
  const lang = locale.split('-')[0]
  const [loading, setLoading] = useState(true)
  const [explorerVisible, setExplorerVisible] = useState()
  const [tab, setTab] = useState('backing')
  const { isAuthed, authToken } = useAuth()
  const { data: owners, error } = useSWR(tab === 'owners' ? ['/api/gb-savings/holdings/list', isAuthed ? authToken : false] : null)
  const ownersLoading = !owners && !error
  const [layoutContext] = useContext(LayoutContext)
  const { currency, productUnit } = layoutContext
  const { productUnitShortLabel, productPriceResolution, productUnitFractionDigits } = resolveProductUnit(productUnit, 1)

  useEffect(() => {
    if (backingTab?.current) {
      const gramchainSupportedLangs = ['en', 'zh', 'de']
      const gramchainSupportedUnits = { gram: 'g', ounce: 'oz' }
      const gramchainSupportedCurrencies = ['AUD', 'SGD', 'EUR', 'HKD', 'CHF', 'CAD', 'GBP', 'MYR', 'USD']
      backingTab.current.src = `https://explorer.goldsavingsaccount.sg/?theme=2&brand=gsa&unit=${gramchainSupportedUnits[productUnit] || 'g'}&currency=${gramchainSupportedCurrencies.includes(currency) ? currency : 'USD'}&lang=${gramchainSupportedLangs.includes(lang) ? lang : 'en'}`
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = explorerVisible ? 'hidden' : 'unset'
  }, [explorerVisible])

  const handleOpen = e => {
    e.target.closest('.' + style.bullion).classList.toggle(style.open)
  }

  const maintenanceMode = false
  let i = 0

  return (
    <div className={c('explore')}>
      <h3>{t('explorer-header')}</h3>
      <div className={c('split', { visible: explorerVisible })}>
        <div>
          <ul>
            <li>
              <div>
                <img src='/images/auditable.svg' width={48} height={48} alt={t('explorer-publiclyauditable-title')} />
                <h4>{t('explorer-publiclyauditable-title')}</h4>
              </div>
              <p>
                {t('explorer-publiclyauditable-body')}
                <button className={c('explorebutton')} onClick={() => setExplorerVisible(true)}>{t('explorer-open')}</button>
              </p>
            </li>
            <li>
              <div>
                <img src='/images/goldbacked.svg' width={48} height={48} alt={t('explorer-backedbyrealgold-title')} />
                <h4>{t('explorer-backedbyrealgold-title')}</h4>
              </div>
              <p>{t('explorer-backedbyrealgold-body')}</p>
            </li>
            <li>
              <div>
                <img src='/images/redemption.svg' width={48} height={48} alt={t('explorer-redeemable-title')} />
                <h4>{t('explorer-redeemable-title')}</h4>
              </div>
              <p>{t('explorer-redeemable-body')}</p>
            </li>
            <li>
              <div>
                <img src='/images/lowfees.svg' width={48} height={48} alt={t('explorer-lowfees-title')} />
                <h4>{t('explorer-lowfees-title')}</h4>
              </div>
              <p>{t('explorer-lowfees-body')} <Link href='/sg/pricing'><a>{t('explorer-lowfees-link')}</a></Link></p>
            </li>
          </ul>
        </div>
        <div className={c('exploreembed', { loading })}>
          <Swipeable onSwipedDown={() => setExplorerVisible(false)} className={c('header')} onClick={() => setExplorerVisible(false)} />
          <div className={c('wrapper')}>
            {maintenanceMode
              ? (
                <div className={c('maintenance')}>
                  <img src='/images/maintenance.svg' width={48} height={48} alt='Maintenance' style={{ transform: 'rotate(180deg)' }} />
                  <br />
                  <h2 style={{ whiteSpace: 'normal' }}>{t('explorer-maintenance-title')}</h2>
                  <p>{t('explorer-maintenance-body')}</p>
                </div>
              )
              : (
                <div className={c('container')}>
                  <div className={c('options')}>
                    <button className={tab === 'backing' ? style.active : ''} onClick={() => setTab('backing')}>{t('explorer-tab-backing')}</button>
                    <button className={tab === 'owners' ? style.active : ''} onClick={() => setTab('owners')}>{t('explorer-tab-owners')}</button>
                  </div>
                  <div className={c('tabs', tab)}>
                    <iframe className={c('backingTab')} ref={backingTab} loading='lazy' onLoad={() => setLoading(false)} />
                    <div className={c('ownersTab', { loading: ownersLoading })} ref={ownersTab}>
                      {!!owners && (
                        <>
                          <div className={c('bullion')} onClick={handleOpen}>
                            <img className={c('goldbar')} src='/images/gold.png' alt={t('explorer-goldbar-alt')} />
                            <div>{t('explorer-gold-grams')}</div>
                            <div className={c('subtext')}>{(owners.reduce?.((a, v) => (v.AuGrams || 0) + a, 0) / productPriceResolution)?.toLocaleString(locale, { maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel} - {t('explorer-accountsamount', { amount: owners.filter?.(o => o.AuGrams > 0)?.length?.toLocaleString(locale, { maximumFractionDigits: 0 }) })}</div>
                          </div>
                          <div className={c('owners')}>
                            {owners.filter?.(o => o.AuGrams > 0)?.map?.(owner => (
                              <div key={i++}>
                                <span className={c('starid')}>{owner.OwnerStarId}:</span>
                                <span>{(owner.AuGrams / productPriceResolution).toLocaleString(locale, { minimumFractionDigits: productUnitFractionDigits, maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel}</span>
                              </div>
                            ))}
                          </div>
                          <div className={c('bullion')} onClick={handleOpen}>
                            <img className={c('silverbar')} src='/images/silver.png' alt={t('explorer-silverbar-alt')} />
                            <div>{t('explorer-silver-grams')}</div>
                            <div className={c('subtext')}>{(owners.reduce?.((a, v) => (v.AgGrams || 0) + a, 0) / productPriceResolution)?.toLocaleString(locale, { maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel} - {t('explorer-accountsamount', { amount: owners.filter?.(o => o.AgGrams > 0)?.length?.toLocaleString(locale, { maximumFractionDigits: 0 }) })}</div>
                          </div>
                          <div className={c('owners')}>
                            {owners.filter?.(o => o.AgGrams > 0)?.map?.(owner => (
                              <div key={i++}>
                                <span className={c('starid')}>{owner.OwnerStarId}:</span>
                                <span>{(owner.AgGrams / productPriceResolution).toLocaleString(locale, { minimumFractionDigits: productUnitFractionDigits, maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel}</span>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}
